<template>
    <div class="account">
        <div class="fun_btns">
            <div
                @click="type = '个人信息'"
                :class="{ actived: type === '个人信息' }"
            >
                个人信息
            </div>
            <div
                @click="type = '学校账号'"
                :class="{ actived: type === '学校账号' }"
                v-if="isManage"
            >
                学校账号
            </div>
        </div>

        <div v-if="type === '个人信息'" class="person">
            <div class="ls_flexalign_center avatar_name_part">
                <div
                    :style="
                        (memberInfo.avatar
                            ? memberInfo.avatar
                            : 'avatar/default.png') | imgFormat(true)
                    "
                >
                    <el-upload
                        class="upload-demo"
                        action=""
                        :headers="myHeaders"
                        :on-change="handleChange"
                        :before-upload="beforeUploadFile"
                        accept=".jpg,.jpeg,.png,.JPG,.JPEG,"
                        :show-file-list="false"
                        multiple
                        :limit="1"
                        :file-list="fileList"
                        name="file"
                        :http-request="uploadURL"
                    >
                        <div class="upload">更换头像</div>
                    </el-upload>
                </div>
            </div>
            <div class="ls_flex">
                <div v-if="!editMode">{{ memberInfo.nickName }}</div>
                <el-input
                    v-model="nickName"
                    style="width: 220px"
                    @change="changeNickname"
                    v-else
                />
                <i
                    class="iconfont icon-xiugai"
                    @click="
                        editMode = !editMode;
                        nickName = memberInfo.nickName;
                    "
                />
            </div>
            <div class="form">
                <el-form
                    ref="form"
                    label-width="100px"
                    style="margin-top: 20px"
                >
                    <el-form-item label="手机号：">
                        已绑定：{{ phoneNumber }}
                        <i class="iconfont icon-xiugai" @click="bandPhone" />
                    </el-form-item>

                    <el-form-item label="机构：">
                        {{ memberInfo.organizationName }}
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div v-else>
            <div class="search_part ls_flexalign_center">
                <el-input v-model="params.name" placeholder="请输入教师名称" />
                <el-button type="primary" @click="getTeacherList">
                    查询
                </el-button>
                <div class="ls_flex_1"></div>
                <el-button type="success" @click="getCode">添加教师</el-button>
            </div>

            <el-table
                :data="teacherList"
                :header-cell-style="{ background: '#FAFAFA' }"
                style="width: 100%; margin-top: 15px"
            >
                <el-table-column label="姓名">
                    <template slot-scope="scope">
                        <span style="margin-right: 5px">
                            {{ scope.row.name }}
                        </span>

                        <el-tag type="danger" v-if="scope.row.isAdmin === 1">
                            管理员
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="联系方式">
                    <template slot-scope="scope">
                        {{ scope.row.phone || "--" }}
                    </template>
                </el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        {{ scope.row.status === 2 ? "未审核" : "已审核" }}
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <div class="opt_part">
                            <template
                                v-if="
                                    scope.row.isAdmin === 0 &&
                                    scope.row.status === 0
                                "
                            >
                                <!-- <span @click="setStatus(scope.row.id, 1)">设为管理员</span> -->
                                <span @click="setStatus(scope.row.id, 2)">
                                    注销
                                </span>
                            </template>
                            <template v-if="scope.row.status === 2">
                                <span @click="exam(scope.row.id, 1)">通过</span>
                                <span @click="exam(scope.row.id, 2)">拒绝</span>
                            </template>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <changePhone ref="changePhone"></changePhone>
        <el-dialog
            title="新增教师"
            :visible.sync="showCode"
            width="410px"
            :close-on-click-modal="false"
        >
            <div class="poster" v-if="showCode">
                <div id="qrcode"></div>
                <div style="text-align: center" v-if="btnShow">
                    <el-button type="success" @click="download">
                        下载海报
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import changePhone from "./components/changePhone.vue";
export default {
    watch: {
        type(val) {
            if (val === "学校账号") {
                this.getTeacherList();
            }
        },
    },
    components: {
        changePhone,
    },
    data() {
        return {
            btnShow: true,
            showCode: false,
            teacherList: [],
            params: {
                name: "",
            },
            type: "个人信息",
            fileList: [],
            ossData: {},
            upCount: 0,
            editMode: false,
            editPMode: false,
            nickName: "",
            professionId: "",
        };
    },
    computed: {
        phoneNumber() {
            return this.$store.state.info.memberInfo.phone
                ? this.$store.state.info.memberInfo.phone.replace(
                      /(\d{3})\d{4}(\d{1})/,
                      "$1****$2"
                  )
                : "";
        },
        memberInfo() {
            // debugger;
            return this.$store.state.info.memberInfo;
        },
        myHeaders() {
            let token = localStorage.getItem("access_token");
            let value = {
                Authorization: token,
                uploadType: "avatar",
            };
            return value;
        },
    },
    methods: {
        //审核
        exam(id, approveStatus) {
            this.$ajax
                .post("user/organization/admin/teacher/approve", {
                    id,
                    approveStatus,
                })
                .then(res => {
                    this.$message.success("审核成功");
                    this.getTeacherList();
                });
        },
        //下载
        download() {
            this.btnShow = false;
            this.$nextTick(() => {
                let shareContent = document.getElementsByClassName("poster")[0];
                const width = shareContent.offsetWidth;
                const height = shareContent.offsetHeight;
                let scale = 1;
                var opts = {
                    windowHeight: height,
                    windowWidth: width,
                    scale: scale,
                    scrollX: 0,
                    scrollY: 0,
                    y: 0,
                    backgroundColor: "transparent", //背景图片透明
                    allowTaint: true, //跨域
                    useCORS: true, //跨域
                };
                html2canvas(shareContent, opts).then(canvas => {
                    let oImg = new Image();
                    oImg = canvas.toDataURL("image/png"); // 导出图片
                    var oA = document.createElement("a");
                    oA.download = "招生海报"; // 设置下载的文件名，默认是'下载'
                    oA.href = oImg;
                    document.body.appendChild(oA);
                    oA.click();
                    oA.remove();
                    this.btnShow = true;
                });
            });
        },
        //获取二维码
        getCode() {
            let url = "";
            this.$ajax.post("/user/teacher/getShareCode", {}).then(result => {
                url = result.data;
                this.showCode = true;
                this.$nextTick(() => {
                    new QRCode("qrcode", {
                        width: 115, // 设置宽度，单位像素
                        height: 115, // 设置高度，单位像素
                        text: url, // 设置二维码内容或跳转地址
                    });
                });
            });
        },
        //设置状态
        setStatus(id, type) {
            if (type === 1) {
                this.$confirm(
                    "管理员将可以拥有查看/新增教师的权限",
                    "是否将该教师设置为管理员？",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                ).then(() => {
                    this.$ajax
                        .post("/user/organization/admin/teacher/setAdmin", {
                            id,
                        })
                        .then(res => {
                            this.$message.success("设置成功");
                            setTimeout(() => {
                                window.location.reload();
                            }, 500);
                        });
                });
            } else {
                this.$confirm("注销操作不可撤回", "是否将该教师注销？", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$ajax
                        .post("/user/organization/admin/teacher/delete", { id })
                        .then(res => {
                            this.$message.success("注销成功");
                            this.getTeacherList();
                        });
                });
            }
        },
        //获取教师列表
        getTeacherList() {
            this.$ajax
                .post("/user/organization/admin/teacher/getList", this.params)
                .then(res => {
                    this.teacherList = res.list;
                });
        },
        bandPhone() {
            console.log(this.memberInfo);
            this.$refs.changePhone.show(this.memberInfo);
        },
        //更换职业
        changeProfession() {
            this.$ajax
                .post("/user/user/edit", {
                    profession: this.professionId,
                })
                .then(res => {
                    if (res.code == 200) {
                        this.$message.success("修改成功");
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    } else {
                        this.$message.warning(res.msg);
                    }
                });
        },
        //更换昵称
        changeNickname() {
            this.$ajax
                .post("/user/user/edit", {
                    name: this.nickName,
                })
                .then(res => {
                    if (res.code == 200) {
                        this.$message.success("修改成功");
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    } else {
                        this.$message.warning(res.msg);
                    }
                });
        },
        // 更改头像
        changeAvatar(url) {
            this.$ajax.post("/user/user/edit", { avatar: url }).then(res => {
                if (res.code == 200) {
                    this.$message.success("修改成功");
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }
            });
        },
        uploadURL(file, fileList) {
            this.uploadOss(file);
        },
        getOssKey() {
            return this.$ajax.post("/user/oss/getKey?pathName=avatar");
        },
        uploadOss(file) {
            var filename = this.ossData.fileName;
            var formData = new FormData(); //注意formData里append添加的键的大小写
            formData.append(
                "key",
                this.ossData.path +
                    filename +
                    file.file.type.replace("image/", ".")
            ); //存储在oss的文件路径
            formData.append("OSSAccessKeyId", this.ossData.accessid); //accessKeyId
            formData.append("policy", this.ossData.policy); //policy
            formData.append("Signature", this.ossData.signature); //签名//如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
            formData.append("file", file.file);
            formData.append("success_action_status", 200); //成功后返回的操作码

            var url = this.ossData.host;
            var fileUrl =
                this.ossData.path +
                filename +
                file.file.type.replace("image/", ".");
            this.$ajax
                .post(url, formData)
                .then(res => {
                    this.upCount = 0;
                    this.changeAvatar(fileUrl);
                })
                .catch(async error => {
                    this.upCount++;
                    if (this.upCount >= 3) {
                        return;
                    }
                    this.upCount++;
                    let ossData = await this.getOssKey();
                    this.ossData = ossData.data;
                    this.uploadOss(file);
                });
        },
        handleChange(file) {
            if (file.response) {
                if (file.response.code == 200) {
                    // debugger;
                    // this.$message.success("上传成功");
                    this.changeAvatar(file.response.msg);
                } else {
                    this.$message.error(file.response.msg);
                }
            }
        },
        beforeUploadFile(file) {
            let testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
            let size = file.size / 1024 / 1024;

            if (!testmsg) {
                this.$message.warning({
                    message: "错误格式提示 ： 图片格式不正确",
                });
                return false;
            }
            if (size > 2) {
                this.$message.warning({
                    message: "图片不可超过2M",
                });
                return false;
            }
        },
    },
    async mounted() {
        let ossData = await this.getOssKey();
        this.ossData = ossData.data;
    },
};
</script>
<style>
.el-form-item:hover i {
    display: inline-block !important;
}

.poster .el-button {
    background: #1d8f5f;
    border-color: #1d8f5f;
    width: 326px;
}
</style>
<style lang="scss" scoped>
.fun_btns {
    margin-bottom: 30px;
    overflow: hidden;

    > div {
        font-size: 20px;
        width: calc(50% - 10px);
        float: left;
        text-align: center;
        line-height: 70px;
        background: #c1d5ed;
        border-radius: 5px;
        cursor: pointer;
        color: #333;

        + div {
            margin-left: 20px;
        }
    }

    .actived {
        background: #499cef;
        color: #fff;
    }
}

::v-deep .el-form-item {
    margin-bottom: 0px;
}

.person {
    .ls_flex {
        font-size: 18px;
        justify-content: center;
        align-items: center;
        margin-top: 10px;

        i {
            font-size: 24px;
            cursor: pointer;
            margin-left: 5px;
        }
    }
}

#qrcode {
    width: 115px;
    height: 115px;
    position: absolute;
    left: 27px;
    top: 265px;
}

.poster {
    width: 370px;
    height: 538px;
    background-image: url("~@/assets/imgs/teacher_poster.png");
    background-size: cover;
    position: relative;
    padding-top: 432px;
    box-sizing: border-box;
}

.search_part {
    margin-top: 20px;

    > * {
        + * {
            margin-left: 7px;
        }

        &:first-child {
            width: 200px;
        }

        &:nth-child(4) {
            width: 220px;
        }
    }
}

.form {
    i {
        display: none;
        font-size: 18px;
        margin-left: 10px;
        cursor: pointer;
    }

    width: 344px;
    margin: 0 auto;
}

.account {
    padding-top: 20px;
    padding-right: 20px;
}

.avatar_name_part {
    justify-content: center;

    .upload {
        width: 150px;
        text-align: center;
        line-height: 40px;
        background: rgba(0, 0, 0, 0.473);
        color: #fff;
        margin-top: 110px;
        display: none;
    }

    > div {
        &:first-child {
            width: 150px;
            height: 150px;
            background-size: cover;
            background-position: center center;
            border-radius: 100%;
            overflow: hidden;

            &:hover {
                .upload {
                    display: block;
                }
            }
        }
    }
}
</style>
