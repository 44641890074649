<!-- 组件说明 -->
<template>
    <div class="changePhone">
        <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogVisible" width="474px" :before-close="handleClose">
            <div>
                <div>
                    <div class="ls_flexalign_center">
                        <span class="label">
                            {{ !showNext ? "手机号" : "新手机号" }}
                        </span>
                        <el-input v-if="!showNext" v-model="query.oldPhoneNum" placeholder="请输入手机号码" :disabled="true" class="ls_flex_1"></el-input>
                        <el-input v-else v-model="query.newPhoneNum" @input="checkLength" class="ls_flex_1" placeholder="请输入手机号码"></el-input>
                        <span v-if="phoneErr" class="tip">
                            {{ phoneErrText }}
                        </span>
                    </div>
                    <div class="ls_flexalign_center" style="margin: 15px 0">
                        <span class="label">验证码</span>
                        <el-input v-if="!showNext" v-model="query.oldCode" @change="checkCode" placeholder="请输入验证码" class="ls_flex_1"></el-input>
                        <el-input v-else v-model="query.newCode" @change="checkCode" placeholder="请输入验证码" class="ls_flex_1"></el-input>
                        <div class="tip color999 ls-cp" v-if="Verification" @click="getSmsCode">
                            获取验证码
                        </div>
                        <div class="tip" v-else>
                            <em class="mr5">{{ timer }}s</em>
                            后重新获取
                        </div>

                        <span v-if="codeErr" class="tip">
                            {{ codeErrText }}
                        </span>
                    </div>
                    <div class="ls_flex" style="justify-content: flex-end">
                        <div class="confirmBtn">
                            <el-button v-if="!showNext" type="primary" size="small" :disabled="phoneRule && codeRule ? false : true" @click="nextFunc">
                                下一步
                            </el-button>
                            <el-button v-else type="primary" size="small" :class="phoneRule && codeRule ? '' : 'disabledStyle'
                                " :disabled="phoneRule && codeRule ? false : true" @click="confirmBtn">
                                完成绑定
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data () {
        return {
            dialogVisible: false,
            Verification: true,
            timer: 60,
            query: {
                oldPhoneNum: "",
                oldCode: "",
                newPhoneNum: "",
                newCode: "",
                // professionValue: "",
            },
            phoneErr: false,
            codeErr: false,
            phoneErrText: "",
            codeErrText: "",
            btnTitle: "获取验证码",
            reflshContent: false,
            disabledBtn: true,
            btnText: "下一步",
            title: "更换手机号",
            descTitle: "解绑后将无法使用该手机号登录此账号！",
            phoneRule: false,
            codeRule: false,
            showNext: false,

            checkPone: false,

            isSendCode: false,
        };
    },
    //监控data中的数据变化
    watch: {},
    //监听属性 类似于data概念
    computed: {},
    methods: {
        show (val) {
            this.query.oldPhoneNum = val.phone;
            if (val.phone) {
                this.phoneRule = true;
            }
            this.dialogVisible = true;
        },
        handleClose () {
            this.dialogVisible = false;
            this.Verification = true;
            this.query = {
                oldPhoneNum: "",
                oldCode: "",
                newPhoneNum: "",
                newCode: "",
            };
            this.phoneErr = false;
            this.codeErr = false;
            this.phoneErrText = "";
            this.codeErrText = "";
            this.btnTitle = "获取验证码";
            this.reflshContent = false;
            this.disabledBtn = true;
            this.btnText = "下一步";
            this.title = "更换手机号";
            this.descTitle = "解绑后将无法使用该手机号登录此账号！";
            this.phoneRule = false;
            this.codeRule = false;
            this.showNext = false;
        },

        checkLength (e) {
            this.query.newPhoneNum = e.replace(/[^\d]/g, "");
            if (e.length > 11) {
                this.query.newPhoneNum = e.slice(0, 11);
            }
        },

        // 验证码倒计时
        codeCountDown () {
            if (this.auth_timer) {
                clearInterval(this.auth_timer);
            }

            this.timer = 60;
            this.isSendCode = true;
            this.Verification = false; //点击button改变v-show的状态
            let auth_timer = setInterval(() => {
                //定时器设置每秒递减
                this.timer--; //递减时间
                if (this.timer <= 0) {
                    this.Verification = true; //60s时间结束还原v-show状态并清除定时器
                    clearInterval(auth_timer);
                    this.auth_timer = null;
                }
            }, 1000);
        },

        // 下一步
        nextFunc () {
            if (!this.isSendCode) {
                this.$message.warning("请先获取验证码");
                return;
            }
            if (this.query.oldCode == "") {
                this.$message.warning("请输入验证码");
                return;
            }

            this.$ajax
                .post("/user/user/verification/phone", {
                    oldSmsCode: this.query.oldCode,
                })
                .then(res => {
                    this.showNext = true;
                    this.btnText = "完成绑定";
                    this.title = "绑定新手机号";
                    this.descTitle =
                        "输入新手机号，绑定后可用该手机号进行登录！";
                    this.phoneRule = false;
                    this.codeRule = false;
                    this.Verification = true;
                    this.isSendCode = false;
                });
        },

        // 验证手机号
        checkPoneRule (val) {
            let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if (!this.query.newPhoneNum) {
                this.phoneErrText = "请输入手机号";
                this.phoneErr = true;
                return false;
            } else {
                this.phoneErr = false;
            }
            if (!reg.test(this.query.newPhoneNum)) {
                this.phoneErrText = "请输入正确的手机号码";
                this.phoneErr = true;
                return false;
            } else {
                this.phoneErr = false;
            }
            this.phoneRule = true;
            return true;
        },

        // 验证验证码
        checkCode (val) {
            if (!val) {
                this.codeErrText = "请输入验证码";
                this.codeErr = true;
                return false;
            } else {
                this.codeErr = false;
            }
            if (val.length != 4) {
                this.codeErrText = "请输入4位验证码";
                this.codeErr = true;
                return false;
            } else {
                this.codeErr = false;
            }
            this.codeRule = true;
            return true;
        },

        // 获取验证码
        getSmsCode () {
            if (this.showNext) {
                if (!this.checkPoneRule()) return false;
                if (this.checkPone) {
                    this.phoneRule = false;
                    this.phoneErrText = "该手机号已被使用，请重新输入。";
                    this.phoneErr = true;
                    return false;
                }
            }

            let ajaxData = {
                smsType: "MODIFY_PHONE",
            };
            if (this.showNext) {
                ajaxData.phone = this.query.newPhoneNum;
            } else {
                ajaxData.phone = this.query.oldPhoneNum;
            }

            this.$ajax.post("/login/sms/send", ajaxData).then(res => {
                if (res.data) {
                    this.codeCountDown();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },

        // 完成绑定
        confirmBtn () {
            let ajaxData = {
                newPhoneNumber: this.query.newPhoneNum,
                newSmsCode: this.query.newCode,
                oldSmsCode: this.query.oldCode,
            };

            if (!this.isSendCode) {
                this.$message.warning("请先获取验证码");
                return;
            }

            this.$ajax.post("/user/user/phone", ajaxData).then(res => {
                if (res.code == 200) {
                    this.$message.success("更换手机成功");
                    // this.$router.push({ path: "/personalCenter" });
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                } else {
                    this.$message.warning(res.msg);
                }
            });
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created () { },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted () { },
};
</script>

<style lang="scss" scoped>
.label {
    width: 70px;
}

.tip {
    margin-left: 10px;
    color: red;
}

.color999 {
    color: #999;
}
</style>
